import * as React from 'react'
import Page from '../components/Page'
import Container from '../components/Container'
import { useState } from 'react';
import styled from 'styled-components';
import PageHeader from '../components/PageHeader';
import CategoryImage from '../components/CategoryImage'
import { getCategoriesForPage } from '../services/api'
import FooterBanner from '../components/DynamicBanner/FooterBanner'
import { breakpoints } from '../styles/variables'

const ThemesContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    margin-top: 0px;
  }
`

const CategoryGrid = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  column-gap: 30px;
  row-gap: 15px;
  align-content: center;
  justify-content: center;
  margin-top: 90px;
  margin-bottom: 120px;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    margin-top: 30px;
  }
`

const ThemesPage = () => {
  const [categoryElements, setCategoryElements] = useState([]);
  const [categoryFetched, setCategoryFetched] = useState(false);

  if (!categoryFetched) {
    setCategoryFetched(true);
    getCategoriesForPage('Teemat').then((data) => {
      console.log('OUR DEIDA', data.data);

      const parsedCategories = [];

      for (let c of data.data) {
        parsedCategories.push(
          <CategoryImage title={c.name} imageSrc={c.imageSrc} type={c.type} />
        )
      }
      // @ts-expect-error
      setCategoryElements(parsedCategories);
    })
  }

  return (
    <Page>
      <ThemesContainer>
        <PageHeader title={'Teemat'} />
        <CategoryGrid>
          {categoryElements}
        </CategoryGrid>
        <FooterBanner />
      </ThemesContainer>
    </Page>
  )
}

export default ThemesPage
